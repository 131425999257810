import { useState } from "react";
import Input from "../form-components/Input";
import { SubStepperProps } from "../type";
import Button from "../form-components/Button";
import { useQuery } from "@tanstack/react-query";
import { DEBUG } from "../MultiStep";
import { useForm } from "react-hook-form";
import Field from "../form-components/Field";

interface GpsResponse {
  features: Array<{
    geometry: { coordinates: [number, number] };
  }>;
}

const fetchGPSData = async (
  address: string,
  plz: string,
  city: string
): Promise<GpsResponse> => {
  if (!DEBUG) {
    const url = `https://gisco-services.ec.europa.eu/api?lang=en&limit=1&q=${encodeURIComponent(
      `${address} ${city} ${plz}`
    )}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return await response.json();
  } else {
    return {
      features: [
        {
          geometry: {
            coordinates: [1.4584659, 43.6490254],
          },
        },
      ],
    };
  }
};

export default function AdressStep({
  nextStep,
  previousStep,
  Data,
  updateData,
}: SubStepperProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSubmit, setisSubmit] = useState(false);
  const isFormValid = Object.values([Data.address, Data.plz, Data.city]).every(
    (value) => !!value
  );

  const { data, isSuccess, isError, error } = useQuery({
    queryKey: ["fetchGPSData"],
    queryFn: () => fetchGPSData(Data.address, Data.plz, Data.city),
    enabled: isFormValid && isSubmit,
    staleTime: 60,
  });

  isFormValid &&
    isSuccess &&
    isSubmit &&
    updateData({
      ...Data,

      coordonnees: {
        latitude: data.features[0].geometry.coordinates[0],
        longitude: data.features[0].geometry.coordinates[1],
      },
    });

  isFormValid && isSuccess && isSubmit && nextStep();

  isError &&
    alert(
      "Erreur lors de la récupération des données GPS: " +
        (error as Error).message
    );

  const onSubmit = (data: any) => {
    setisSubmit(true);
    if (!isFormValid) {
      alert("Veuillez remplir tous les champs");
      return;
    }
    updateData({ ...Data, ...data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-wrap mb-6">
        <Field label="Adresse" error={errors?.address}>
          <Input
            {...register("address", {
              required: "Ce champ est obligatoire",
            })}
            defaultValue={Data.address}
            isError={!!errors?.address}
          />
        </Field>
        <Field label="Code postal" error={errors?.plz}>
          <Input
            {...register("plz", {
              required: "Ce champ est obligatoire",
            })}
            defaultValue={Data.plz}
            isError={!!errors?.plz}
          />
        </Field>

        <Field label="Ville" error={errors?.city}>
          <Input
            {...register("city", {
              required: "Ce champ est obligatoire",
            })}
            defaultValue={Data.city}
            isError={!!errors?.city}
          />
        </Field>
      </div>
      <div className="grid grid-cols-2">
        <Button onClick={() => previousStep()}>Retour</Button>
        <Button type="submit">Suivant</Button>
      </div>
    </form>
  );
}

import { SubStepperProps } from "../type";
import Button from "../form-components/Button";
import { useQuery } from "@tanstack/react-query";

import configPrix from "../../configPrix.json";
import { DEBUG } from "../MultiStep";

const fetchGPSData = async (
  address: string,
  plz: string,
  city: string
): Promise<any> => {
  if (DEBUG) {
    // const url = `https://gisco-services.ec.europa.eu/api?lang=en&limit=1&q=${encodeURIComponent(
    //   `${address} ${city} ${plz}`
    // )}`;
    const url =
      "https://re.jrc.ec.europa.eu/api/v5_2/PVcalc?outputformat=basic&lat=43.649&lon=1.459&raddatabase=PVGIS-SARAH2&browser=0&peakpower=1&loss=14&mountingplace=free&pvtechchoice=crystSi&angle=35&aspect=0&usehorizon=1&userhorizon=&js=1";
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return await response.json();
  } else {
    return {
      features: [
        {
          geometry: {
            coordinates: [1.4584659, 43.6490254],
          },
        },
      ],
    };
  }
};

export default function FinalStep({ previousStep, Data }: SubStepperProps) {
  const { data, isSuccess, isError, error, isLoading } = useQuery({
    queryKey: ["fetchGPSData"],
    queryFn: () => fetchGPSData(Data.address, Data.plz, Data.city),
    // enabled: isFormValid && isSubmit,
    staleTime: 60,
  });

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  if (isSuccess === false) {
    return <div>Error</div>;
  }

  return (
    <>
      {JSON.stringify(configPrix, null, 2)}
      <div className="flex flex-wrap mb-6">
        {Data && <pre>{JSON.stringify(Data, null, 2)}</pre>}
      </div>
      <div className="grid grid-cols-2">
        <Button onClick={() => previousStep()}>Retour</Button>
      </div>
    </>
  );
}

import Input from "../form-components/Input";
import { SubStepperProps } from "../type";
import Button from "../form-components/Button";
import SelectInput from "../form-components/SelectInput";
import { useForm, useFieldArray } from "react-hook-form";
import Field from "../form-components/Field";
import ToogleInput from "../form-components/ToogleInput";

const defaultToiture = {
  longueur: "50",
  largeur: "50",
  orientation: "",
  typeToiture: "",
  ombreToiture: false,
};
export default function SolarStep({
  nextStep,
  previousStep,
  Data,
  updateData,
}: SubStepperProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      consommationAnnuelle: Data.consommationAnnuelle || 0,
      isPower: Data.isPower || false,
      typeCourant: Data.typeCourant || "monophase",
      toitures: Data.toitures || [defaultToiture],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "toitures",
  });

  const onSubmit = (data: any) => {
    updateData({ ...Data, ...data });
    nextStep();
  };
  console.log(errors);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-wrap mb-6">
        <Field
          label="Consommation annuelle d’électricité"
          error={errors?.consommationAnnuelle}
        >
          <Input
            {...register("consommationAnnuelle", {
              required: "Ce champ est obligatoire",
              valueAsNumber: true,
            })}
            defaultValue={Data.consommationAnnuelle}
            type="number"
            isError={!!errors?.consommationAnnuelle}
          />
        </Field>
        <Field label="Type courant">
          <SelectInput
            {...register(`typeCourant`)}
            options={[
              { label: "mophase", value: "mophase" },
              { label: "triphase", value: "triphase" },
            ]}
          />
        </Field>
        {fields.map((field, index) => (
          <div key={field.id} className="flex flex-wrap mb-6">
            <Field
              label={`Toiture ${index + 1} - Longueur (en m)`}
              // error={errors?.toitures?.[index]?.longueur.message}
              // error={`toitures.${index}.message`}
            >
              <Input
                type="number"
                {...register(`toitures.${index}.longueur`, {
                  required: "Ce champ est obligatoire",
                })}
              />
            </Field>
            <Field label={`Toiture ${index + 1} - Largeur (en m)`}>
              <Input
                type="number"
                {...register(`toitures.${index}.largeur`, { required: true })}
              />
            </Field>
            <Field label={`Toiture ${index + 1} - Orientation`}>
              <SelectInput
                {...register(`toitures.${index}.orientation`)}
                options={[
                  { label: "Sud", value: 0 },
                  { label: "Sud-Ouest", value: 45 },
                  { label: "Sud-Est", value: -45 },
                  { label: "Est", value: -90 },
                  { label: "Ouest", value: 90 },
                  { label: "Nord-Ouest", value: 125 },
                  { label: "Nord-Est", value: -125 },
                  { label: "Nord", value: 180 },
                ]}
              />
            </Field>
            <Field label={`Toiture ${index + 1} - Type de toiture`}>
              <SelectInput
                {...register(`toitures.${index}.typeToiture`)}
                options={[
                  { label: "tuile mécanique", value: "tuile mécanique" },
                  { label: "tuile canal", value: "tuile canal" },
                  { label: "bac acier", value: "bac acier" },
                  { label: "fibrociment", value: "fibrociment" },
                  { label: "toiture terrasse", value: "toiture terrasse" },
                ]}
              />
            </Field>
            <Field label={`Toiture ${index + 1} - Ombre sur la toiture`}>
              <ToogleInput {...register(`toitures.${index}.ombreToiture`)} />
            </Field>
            <Button type="button" onClick={() => remove(index)}>
              Supprimer cette toiture
            </Button>
            <br />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-3">
        <Button type="button" onClick={() => append(defaultToiture)}>
          Ajouter une toiture
        </Button>
        <Button onClick={() => previousStep()}>Retour</Button>
        <Button type="submit">Suivant</Button>
      </div>
    </form>
  );
}

import { InputHTMLAttributes,forwardRef } from "react";

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ isError, ...props }, ref) => {
    return (
      <input
        className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
          isError && "border-red-500"
        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
        ref={ref}
        {...props}
      />
    );
  }
);

export default Input;

import { PropsWithChildren } from "react";
import LabelInput from "./LabelInput";
import { FieldError } from "react-hook-form";

interface FieldProps {
  label: string;
  error?: FieldError | any;
}

export default function Field({
  label,
  error,
  children,
}: PropsWithChildren<FieldProps>) {
  return (
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <LabelInput>{label}</LabelInput>

      {children}

      {error && <p className="text-red-500 text-xs italic">{error.message}</p>}
    </div>
  );
}

import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { typeDataForm } from "./type";
import AdressStep from "./Steps/AdressStep";
import InfoStep from "./Steps/InfoStep";
import SolarStep from "./Steps/SolarStep";
import FinalStep from "./Steps/FinalStep";
import BarStep from "./form-components/BarStep";
import ProgressBar from "./form-components/ProgressBar";

const queryClient = new QueryClient();

export const DEBUG = true;

const defaultData: typeDataForm = DEBUG
  ? {
      name: "Dupont",
      firstname: "Fabien",
      address: "25 rue frédéric chopin",
      plz: "31200",
      city: "Toulouse",
      coordonnees: { latitude: 0, longitude: 0 },

      typeCourant: "monophase",
      consommationAnnuelle: 3000,
      email: "fabien@cool.com",
      phone: "0612345678",
    }
  : {
      name: "",
      firstname: "",
      address: "",
      plz: "",
      city: "",
      coordonnees: { latitude: 0, longitude: 0 },
      typeCourant: "monophase",
      consommationAnnuelle: 0,
      email: "",
      phone: "",
    };

function MultiStep() {
  const [currentStep, setCurrentStep] = useState(0);
  const [Data, setData] = useState<typeDataForm>(defaultData);

  const steps = [InfoStep, AdressStep, SolarStep, FinalStep];
  const stepsName = ["Informations", "Adresse", "Toiture", "Devis"];
  const StepComponent = steps[currentStep];

  const nextStep = () =>
    setCurrentStep(Math.min(currentStep + 1, steps.length - 1));

  const previousStep = () => setCurrentStep(Math.max(currentStep - 1, 0));

  const updateData = (newData: Partial<FormData>) =>
    setData({ ...Data, ...newData });

  return (
    <QueryClientProvider client={queryClient}>
      <ProgressBar step={currentStep} steps={stepsName} />
      <BarStep step={currentStep} steps={stepsName} />
      <div className=" flex flex-col sm:flex-row sm:my-20">
        <div className="sm:basis-1/4  ">
          <img src="bary_energie.jpg" alt="logo" />
        </div>
        <div className="sm:px-32 sm:basis-3/4">
          <StepComponent
            nextStep={nextStep}
            previousStep={previousStep}
            Data={Data}
            updateData={updateData}
          />
        </div>
      </div>
      {/* {Data && <pre>{JSON.stringify(Data, null, 2)}</pre>} */}
    </QueryClientProvider>
  );
}

export default MultiStep;
